import React from 'react';
import ArticlesGrid from '@components/organisms/ArticlesGrid/ArticlesGrid';
import munger from './article.munger';

const ArticlesTemplate = ({ articles }) => {
  const articlesData = articles.map((article) => munger(article));

  // Sort articles by date and them to 'month-year' groups.
  // Eg. {'April 2021': [...articles], 'May 2021: [...articles]'}.
  const monthlyArticlesData = articlesData
    .sort((a, b) => b.created - a.created)
    .reduce((articlesData, article) => {
      const monthCreated = article.created.toLocaleDateString(`en-GB`, {
        year: `numeric`,
        month: `long`
      });

      if (!articlesData[monthCreated]) {
        articlesData[monthCreated] = [];
      }

      articlesData[monthCreated].push(article);

      return articlesData;
    }, {});

  return (
    <div className="mars-container">
      <h1 className="mars-text--center mars-alpha mars-padding-y--xl">
        Articles
      </h1>

      {Object.keys(monthlyArticlesData).map((month, i) => {
        return (
          <ArticlesGrid
            // Display max 1 featured and 4 regular articles.
            articles={monthlyArticlesData[month].slice(0, 5)}
            preface={month}
            reverse={i % 2 ? true : false}
            key={month}
          />
        );
      })}
    </div>
  );
};

export default ArticlesTemplate;
