import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import ArticlesTemplate from '@components/templates/ArticlesTemplate/ArticlesTemplate';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

import '@styles/site.scss';

const ArticlesPage = ({ data, location }) => {
  return (
    <Layout location={location} title={``}>
      <GTMPageLoad
        {...{
          page_type: `articles`,
          page_taxonomy_field: `Articles`,
          page_name: `Articles`
        }}
      />
      <SEO title="Home" />
      <ArticlesTemplate
        articles={data.allNodeArticleJson.nodes}
        location={location}
      />
    </Layout>
  );
};

export default ArticlesPage;

export const pageQuery = graphql`
  query ArticlesQuery {
    allNodeArticleJson {
      nodes {
        drupal_id
        title
        field_article_description
        path {
          alias
        }
        created
        field_article_featured
        field_article_read_time
        field_article_authored_by
        relationships {
          field_article_image {
            image {
              alt
            }
            relationships {
              image {
                uri {
                  url {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
