export default (article) => {
  const {
    drupal_id,
    title,
    path,
    field_article_description,
    relationships,
    created,
    field_article_read_time,
    field_article_featured
  } = article;

  return {
    id: drupal_id,
    title: title,
    url: path.alias,
    description: field_article_description,
    imageUrl:
      relationships.field_article_image?.relationships.image.uri.url || null,
    imageAlt: relationships.field_article_image?.image.alt,
    created: Object.freeze(new Date(created)),
    readTime: field_article_read_time,
    featured: field_article_featured
  };
};
